$chart-background-color: #f4f6f6;
$muted-text-color: #4f4d4d;
$border-color: #ececec;
$app-background-color: #dad7da;
$summary-container-background-color: #fff;
$default-print-background-color: #FFF;
$default-print-color: #444;

html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
main {
  background-color: $app-background-color;
  min-height: 100%;
}
.print-only {
  display: none;
}
.summaries {
  padding: 8px 16px;
  background-color: $summary-container-background-color;
}

p {
  line-height: 1.5;
}
.chart__container {
  background-color: $chart-background-color;
}
.response-table {
  min-width: 45%;
  max-width: 100%;
}
@media (min-width: 600px) {
  .response-table {
    max-width: 50%;
  }
}

.invisible {
  display: none;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.flex {
  display: flex !important;
}
.muted-text {
  color: $muted-text-color;
}
.capitalized-text {
  text-transform: capitalize;
}
.no-wrap-text {
  white-space: nowrap;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.header-logo {
  width: 120px;
}
@media (min-width: 600px) {
  .header-logo {
    width: 180px;
  }
}
@media print {
  @page {
    margin: 5mm 5mm 5mm 5mm;
  }
  .print-hidden {
    display: none !important;
  }
  .print-only {
    display: block;
  }
  .selector-stats-wrapper {
    background-color: $default-print-background-color !important;
    margin-top: 8px !important;
    margin-bottom: 16px !important;
    padding: 0 !important;
  }
  header {
    background-color: $default-print-background-color !important;
    box-shadow: none !important;
  }
  .scoring-summary-container {
    padding: 0 !important;
  }
  .chart__container {
    width: 100% !important;
    background-color: $default-print-background-color !important;
  }
  .table-container {
    width: 100% !important;
    min-width: 100% !important;
  }
  .summary-container:not(:first-of-type) {
    page-break-inside: avoid;
  }
  .questionnaire-title {
    page-break-after: avoid;
    width: 100%;
    border-bottom: 1px solid $default-print-color !important;
  }
  .accordion-wrapper {
    page-break-inside: avoid;
  }
  .MuiToolbar-root {
    background-color: $default-print-background-color !important;
    box-shadow: none !important;
  }
  .MuiTablePagination-toolbar {
    display: none !important;
  }
  .MuiAccordion-root {
    box-shadow: none !important;
    table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      th, td {
        border: 1px solid $border-color;
        text-align: center;
        background-color: $summary-container-background-color !important;
        color: $default-print-color !important;
        padding: 4px;
      }
      td:empty {
        border-bottom: 0 !important;
      }
    }
  }
  .MuiAccordionDetails-root {
    padding: 8px 0 !important;
  }
  .table-container {
    border: 0 !important;
    box-shadow: none !important;
  }
  .MuiTab-root.Mui-selected {
    color: #444 !important;
    padding-bottom: 0 !important;
    border-bottom: 2px solid $border-color;
  }
  .MuiTabs-indicator {
    background-color: transparent !important;
  }
}
